import { provideDiTokenValue } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { LOCATION } from '@bcflit-v1-configurators/tokens/app/token';
import { isServer } from 'lit';

type LocationKind = {
  origin: string;
  pathname: string;
  search: string;
  hash: string;
};

function mapToFullPath(location: LocationKind): string {
  return `${location.pathname}${location.search}${location.hash}`;
}

export class HistoryUrl {
  private _history: string[] = [];

  constructor(private _location: Location) {
    if (!isServer) {
      window.addEventListener('popstate', () => this._popState());
      window.addEventListener('hashchange', () => this._checkAtNextTick());
      window.addEventListener('routerUpdated', () => this._checkAtNextTick());
    } else {
      this._checkAtNextTick();
    }

    this._history = this._getFromMemory();
  }

  private _checkAtNextTick(): void {
    if (isServer) {
      this._checkUrl();
      return;
    }
    setTimeout(() => {
      this._checkUrl();
    }, 10);
  }

  private _checkUrl(): void {
    const newPath: string = mapToFullPath(this._location);
    const lastHistoryUrl: string | undefined = this._history[this._history.length - 1];
    if (lastHistoryUrl === newPath) {
      return;
    }
    this._history.push(newPath);
    this._updateMemory();
  }

  private _popState(): void {
    this._history = this._history.slice(0, -1);
    this._updateMemory();
  }

  public get history(): string[] {
    return this._history.slice();
  }

  private _updateMemory(): void {
    if (!isServer) {
      window.sessionStorage.setItem('historyUrls', JSON.stringify(this.history));
    }
  }

  private _getFromMemory(): string[] {
    if (isServer) {
      return [];
    }
    const fromMemory: string | undefined = window.sessionStorage.getItem('historyUrls') ?? undefined;
    if (fromMemory) {
      return JSON.parse(fromMemory);
    }
    return [];
  }
}

export function provideHistoryUrl(): HistoryUrl {
  return provideSingleton(HistoryUrl, () => new HistoryUrl(provideDiTokenValue(LOCATION)));
}
