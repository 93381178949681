import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { EnvTma } from '@bcflit-env/types';
import { isServer } from 'lit';

export class SharedSettings {
  private _env!: EnvTma;

  public set env(env: EnvTma) {
    if (this._env && !isServer) {
      throw new Error('you can setup environment just once');
    }
    this._env = env;
  }

  public get env(): EnvTma {
    if (!this._env) {
    }
    return this._env;
  }
}

export function provideSharedSettings(): SharedSettings {
  return provideSingleton(SharedSettings, () => new SharedSettings());
}
