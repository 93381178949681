import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { isServer } from 'lit';

export class SvgIconAppComputedStylesService {
  private _computedStyles: CSSStyleDeclaration | undefined;

  public setComputedStyles(computedStylesFn: () => CSSStyleDeclaration): void {
    if (isServer) {
      return;
    }
    this._computedStyles = computedStylesFn();
  }

  public getCssVarValue(cssVar: string): string | undefined {
    if (isServer) {
      return undefined;
    }
    if (!this._computedStyles) {
      return undefined;
    }
    const value: string = this._computedStyles.getPropertyValue(`--${cssVar}`);
    if (value.length === 0) {
      return undefined;
    }
    return value.trim();
  }
}

export function provideSvgIconAppComputedStylesService(): SvgIconAppComputedStylesService {
  return provideSingleton(SvgIconAppComputedStylesService, () => new SvgIconAppComputedStylesService());
}
