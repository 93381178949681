import { provideDiTokenValue } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { LOCATION } from '@bcflit-v1-configurators/tokens/app/token';
import {
  CmsSeoMetaUpdaterBloc,
  provideCmsSeoMetaUpdaterBloc
} from '@tmf-logic-cms/bloc/cms-seo-meta-updater/cms-seo-meta-updater.bloc';
import { UnsubscribableController } from '@tmf-shared-classes/unsubscribable-controller';
import { transferRxNext } from '@tmf-shared-misc/rx-helpers';
import { DomainSettings } from '@tmf-shared-models/domain-settings';
import { ActivatedLang, provideActivatedLang } from '@tmf-shared-platform/activated-lang/activated-lang';
import { ActivatedUrl, provideActivatedUrl } from '@tmf-shared-platform/activated-url/activated-url';
import { MetaService } from '@tmf-ui-shared/seo/meta.service';
import { TitleService } from '@tmf-ui-shared/seo/title.service';
import { SharedSettings, provideSharedSettings } from '@tmp-shared-settings/settings/shared-settings';
import { ReactiveControllerHost } from 'lit';
import { combineLatest, map } from 'rxjs';
import { mapToUpdatedSegment } from './pure-utils/map-to-updated-segment';

export class CmsSeoMetaUpdaterController extends UnsubscribableController<CmsSeoMetaUpdaterController> {
  constructor(
    protected override _host: ReactiveControllerHost,
    protected override _bloc: CmsSeoMetaUpdaterBloc,
    private _metaService: MetaService,
    private _titleService: TitleService,
    private _activatedUrl: ActivatedUrl,
    private _sharedSettings: SharedSettings,
    private _activatedLang: ActivatedLang,
    private _location: Location
  ) {
    super(_host);
  }

  public hostConnected(): void {
    this._bloc.currentHostname$.next(this._location.hostname);
    this._sub = this._activatedUrl.url$.subscribe(transferRxNext(this._bloc.currentUrl$));
    this._sub = this._activatedUrl.fullUrl$.subscribe(transferRxNext(this._bloc.currentFullUrl$));

    this._sub = this._activatedLang.currentLang$.subscribe(this._updateHtmlLang());

    this._sub = combineLatest([
      this._activatedUrl.fullUrl$.pipe(map((url: string) => url.replace(/[0-9]/g, ''))),
      this._activatedLang.currentLang$,
      this._bloc.getDomainSettings()
    ]).subscribe(this._updateHreflang());

    this._sub = this._bloc.getMetaTitle().subscribe((title: string) => this._titleService.setTitle(title));

    this._sub = this._bloc
      .getMetaDescription()
      .subscribe((description: string) => this._metaService.updateTag({ name: 'description', content: description }));

    this._sub = this._bloc
      .getMetaRobots()
      .subscribe((robots: string) => this._metaService.updateTag({ name: 'robots', content: robots }));

    this._sub = combineLatest([
      this._bloc.getMetaCanonical(),
      this._activatedLang.currentLang$,
      this._activatedUrl.fullUrl$,
      this._bloc.getDomainSettings()
    ]).subscribe(this._updateCanonicalUrl());
  }

  private _updateCanonicalUrl() {
    return ([urlPathname, currentLang, fullUrl, domainSettings]: [
      string | undefined,
      string,
      string,
      DomainSettings
    ]): void => {
      const head: HTMLHeadElement = document.head;
      let element: HTMLLinkElement | null = document.querySelector(`link[rel='canonical']`);
      if (!element) {
        element = document.createElement('link') as HTMLLinkElement;
        head.appendChild(element);
      }
      const currentUrl: URL = new URL(fullUrl);
      const pathnameToSet: string = urlPathname ?? currentUrl.pathname;
      const activeDomainWithNumber: string | undefined =
        domainSettings.settings?.[currentLang.toUpperCase()]?.activeDomain;

      if (activeDomainWithNumber) {
        const newUrl: URL = new URL(pathnameToSet, `https://${activeDomainWithNumber}`);
        element.setAttribute('rel', 'canonical');
        element.setAttribute('href', newUrl.toString());
        return;
      }
      const newUrl: URL = new URL(pathnameToSet, `https://${currentUrl.hostname.replace(/[0-9]/g, '')}`);
      element.setAttribute('rel', 'canonical');
      element.setAttribute('href', newUrl.toString());
      return;
    };
  }

  private _updateHtmlLang() {
    return (lang: string): void => {
      const htmlTag: HTMLHtmlElement = document.querySelector('html')!;
      htmlTag.lang = lang;
    };
  }

  private _updateHreflang() {
    return ([url, currentLang, domainSettings]: [string, string, DomainSettings]): void => {
      const envLangs: string[] = this._sharedSettings.env.langs;

      const availableLangs: string[] = ['x-default', ...envLangs];
      const relationLinks: HTMLLinkElement[] = Array.from(document.querySelectorAll(`link[rel='alternate']`));

      for (const lang of availableLangs) {
        const hreflangLanguage: string = lang === 'x-default' ? currentLang : lang;
        const activeDomainWithNumber: string | undefined = domainSettings.settings?.[lang.toUpperCase()]?.activeDomain;

        const detectUrlToCheck = (): string => {
          const parsedUrl: URL = new URL(url);
          const activeDomainForCurrentLang: string | undefined =
            domainSettings.settings?.[currentLang.toUpperCase()]?.activeDomain;
          if (lang === 'x-default' && activeDomainForCurrentLang) {
            return new URL(parsedUrl.pathname, `https://${activeDomainForCurrentLang}`).toString();
          }

          return activeDomainWithNumber
            ? new URL(parsedUrl.pathname, `https://${activeDomainWithNumber}`).toString()
            : url;
        };

        const urlToCheck: string = detectUrlToCheck();
        const updatedUrlByLangSegment: string = urlToCheck
          .split('/')
          .map(mapToUpdatedSegment(hreflangLanguage, availableLangs))
          .join('/');

        if (relationLinks.length > envLangs.length) {
          relationLinks
            .find((htmlLinkElement: HTMLLinkElement) => htmlLinkElement.hreflang === lang)
            ?.setAttribute('href', updatedUrlByLangSegment);
        } else {
          const element: HTMLLinkElement = document.createElement('link') as HTMLLinkElement;
          document.head.appendChild(element);
          element.setAttribute('rel', 'alternate');
          element.setAttribute('hreflang', lang);
          element.setAttribute('href', updatedUrlByLangSegment);
        }
      }
    };
  }
}

export function provideCmsSeoMetaUpdaterController(host: ReactiveControllerHost): CmsSeoMetaUpdaterController {
  return new CmsSeoMetaUpdaterController(
    host,
    provideCmsSeoMetaUpdaterBloc(),
    new MetaService(),
    new TitleService(),
    provideActivatedUrl(),
    provideSharedSettings(),
    provideActivatedLang(),
    provideDiTokenValue(LOCATION)
  );
}
