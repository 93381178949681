// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class DiToken<T> {
  constructor(public name: string) {}
}

export function clearDiStore(): void {
  const globalAny: any = globalThis;
  globalAny['_diTokensStore'] = undefined;
}

function registerDiTokensStore(): void {
  const globalAny: any = globalThis;
  if (!globalAny['_diTokensStore']) {
    globalAny['_diTokensStore'] = new Map();
  }
}

function getDiTokensStore<T>(): Map<string, T | undefined> {
  const globalAny: any = globalThis;
  return globalAny['_diTokensStore'];
}

export function registerDiToken<T>(diToken: DiToken<T>, tokenValueFactory: () => T): void {
  registerDiTokensStore();
  const diStore: Map<string, T | undefined> = getDiTokensStore();
  if (!diStore.has(diToken.name)) {
    diStore.set(diToken.name, tokenValueFactory());
  }
}

export function provideDiTokenValue<T>(diToken: DiToken<T>): T {
  registerDiTokensStore();
  const diStore: Map<string, T | undefined> = getDiTokensStore();
  if (!diStore.has(diToken.name)) {
    throw new Error('provideDiTokenValue error, missing diToken: ' + diToken.name);
  }
  return diStore.get(diToken.name)!;
}

export function provideDiTokenValueWithDefault<T>(diToken: DiToken<T>, defaultVAlue: T): T {
  registerDiTokensStore();
  const diStore: Map<string, T | undefined> = getDiTokensStore();
  if (!diStore.has(diToken.name)) {
    return defaultVAlue;
  }
  return diStore.get(diToken.name)!;
}
