import { UnsubscribableTmfLitElement } from '@tmf-shared-classes/unsubscribable-tmf-lit-element';
import { ActivatedLang, provideActivatedLang } from '@tmf-shared-platform/activated-lang/activated-lang';
import { ActivatedUrl, provideActivatedUrl } from '@tmf-shared-platform/activated-url/activated-url';
import {
  CmsSeoMetaUpdaterController,
  provideCmsSeoMetaUpdaterController
} from '@tmf-ui-cms-controller/cms-seo-meta-updater/cms-seo-meta-updater.controller';
import { SharedSettings, provideSharedSettings } from '@tmp-shared-settings/settings/shared-settings';
import { TemplateResult, html } from 'lit';

export class CmsSeoMetaUpdaterSmartElement extends UnsubscribableTmfLitElement {
  public static override elementSelector: string = 'tmf-jzm-cms-seo-meta-updater-smart';

  private _controller: CmsSeoMetaUpdaterController = provideCmsSeoMetaUpdaterController(this);

  constructor(
    private _activatedLang: ActivatedLang = provideActivatedLang(),
    private _activatedUrl: ActivatedUrl = provideActivatedUrl(),
    private _sharedSettings: SharedSettings = provideSharedSettings()
  ) {
    super();
  }

  public override connectedCallback(): void {
    super.connectedCallback();
  }

  public override render(): TemplateResult {
    return html``;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'tmf-jzm-cms-seo-meta-updater-smart': CmsSeoMetaUpdaterSmartElement;
  }
}
