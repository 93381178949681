import { provideDiTokenValue } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { LOCAL_STORAGE } from '@bcflit-v1-configurators/tokens/app/token';
import { LocalStorageKeys } from './enums';

export class LocalStorage {
  constructor(private _storageApi: Storage) {}

  public clear(): void {
    this._storageApi.clear();
  }

  public getString(key: LocalStorageKeys): string | undefined {
    return this._storageApi.getItem(key) ?? undefined;
  }

  public setString(key: LocalStorageKeys, value: string): void {
    return this._storageApi.setItem(key, value);
  }

  public getNumber(key: LocalStorageKeys): number {
    return Number(this._storageApi.getItem(key));
  }

  public setNumber(key: LocalStorageKeys, value: number): void {
    this._storageApi.setItem(key, value?.toString());
  }

  public getBoolean(key: LocalStorageKeys): boolean {
    return this._storageApi.getItem(key) === 'true';
  }

  public setBoolean(key: LocalStorageKeys, value: boolean): void {
    this._storageApi.setItem(key, value.toString());
  }

  public getJson<T>(key: LocalStorageKeys): T | undefined {
    const value: string | null = this._storageApi.getItem(key);
    return value ? JSON.parse(value) : undefined;
  }

  public setJson<T>(key: LocalStorageKeys, value: T): void {
    this._storageApi.setItem(key, JSON.stringify(value));
  }

  public remove(key: LocalStorageKeys): void {
    this._storageApi.removeItem(key);
  }
}

export function provideLocalStorage(): LocalStorage {
  return provideSingleton(LocalStorage, () => new LocalStorage(provideDiTokenValue(LOCAL_STORAGE)));
}
