import { SubscriptionLike } from 'rxjs';
import { DoRouterReplaceUrlData, DoRouterUpdateQueryParamsData, DoRouterUpdateUrlData, WeakListener } from './types';

class WeakListenerUnsubscribable implements SubscriptionLike {
  public closed: boolean = false;

  constructor(private _type: WeakListener) {}

  public unsubscribe(): void {
    const global: any = globalThis as any;
    global[this._type] = undefined;
    this.closed = true;
  }
}

export function weakListener(type: 'do-open-game-info-click', cb: () => void): SubscriptionLike;
export function weakListener(type: 'do-open-game-fullscreen-click', cb: () => void): SubscriptionLike;
export function weakListener(type: 'do-router-update-url', cb: (data: DoRouterUpdateUrlData) => void): SubscriptionLike;
export function weakListener(
  type: 'do-router-replace-url',
  cb: (data: DoRouterReplaceUrlData) => void
): SubscriptionLike;
export function weakListener(
  type: 'router-back-from-open-game-to-game-list-category',
  cb: () => void
): SubscriptionLike;
export function weakListener(
  type: 'do-router-update-query-params',
  cb: (data: DoRouterUpdateQueryParamsData) => void
): SubscriptionLike;
export function weakListener<T>(type: WeakListener, cb: (data: T) => void): SubscriptionLike {
  const global: any = globalThis as any;
  if (global[type]) {
    throw new Error('weakListener can only listen once');
  }
  const weakListenerSub: WeakListenerUnsubscribable = new WeakListenerUnsubscribable(type);
  global[type] = (data: T): void => cb(data);
  return weakListenerSub;
}
