import { UnsubscribableTmfLitElement } from '@tmf-shared-classes/unsubscribable-tmf-lit-element';
import { ActivatedUrl, provideActivatedUrl } from '@tmf-shared-platform/activated-url/activated-url';
import {
  CmsSeoJsonLdUpdaterController,
  provideCmsSeoJsonLdUpdaterController
} from '@tmf-ui-cms-controller/cms-seo-json-ld-updater/cms-seo-json-ld-updater.controller';
import { TemplateResult, html } from 'lit';

export class CmsSeoJsonLdUpdaterSmartElement extends UnsubscribableTmfLitElement {
  public static override elementSelector: string = 'tmf-jzm-cms-seo-json-ld-updater-smart';

  private _controller: CmsSeoJsonLdUpdaterController = provideCmsSeoJsonLdUpdaterController(this);

  constructor(private _activatedUrl: ActivatedUrl = provideActivatedUrl()) {
    super();
  }

  public override connectedCallback(): void {
    super.connectedCallback();
  }

  public override render(): TemplateResult {
    return html``;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'tmf-jzm-cms-seo-json-ld-updater-smart': CmsSeoJsonLdUpdaterSmartElement;
  }
}
