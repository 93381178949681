import { PageScrollerService, pageScrollerService } from '@tmf-shared-platform/page-scroller/page-scroller.service';
import { isServer } from 'lit';
import { auditTime, fromEvent } from 'rxjs';

export class RouterPageScrollerService {
  constructor(private _pageScrollerService: PageScrollerService) {}

  public init(): void {
    if (isServer) {
      return;
    }
    fromEvent(window, 'routerUpdated')
      .pipe(auditTime(100))
      .subscribe(() => this._pageScrollerService.scrollTop());
  }
}

export const routerPageScrollerService: RouterPageScrollerService = new RouterPageScrollerService(pageScrollerService);
