import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { createStore, get, set, UseStore } from 'idb-keyval';
import { isServer } from 'lit';
import { CookiesConsentType } from './cookies-consent-types';

export class CookiesConsentMemory {
  private _cookiesConsentStoreKey: string = 'cookies-consent';
  private _useStoreRef!: UseStore;

  public async acceptedCookiesConsent(): Promise<CookiesConsentType[] | undefined> {
    if (isServer) {
      return;
    }
    return await get(this._cookiesConsentStoreKey, this._cookiesConsentStore);
  }

  public async setAcceptedCookiesConsent(consent: CookiesConsentType[]): Promise<void> {
    if (isServer) {
      return;
    }
    await set(this._cookiesConsentStoreKey, consent, this._cookiesConsentStore);
  }

  private get _cookiesConsentStore(): UseStore {
    return (this._useStoreRef ??= createStore(this._cookiesConsentStoreKey, this._cookiesConsentStoreKey));
  }
}

export function provideCookiesConsentMemory(): CookiesConsentMemory {
  return provideSingleton(CookiesConsentMemory, () => new CookiesConsentMemory());
}
