import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { getDomainSettings } from '@tmf-logic-api-access/http/ext-api/apis/get-domain-settings';
import { ExtApi, provideExtApi } from '@tmf-logic-api-access/http/ext-api/ext-api';
import { DomainSettings } from '@tmf-shared-models/domain-settings';
import { provideSharedSettings, SharedSettings } from '@tmp-shared-settings/settings/shared-settings';
import { from, Observable, shareReplay } from 'rxjs';

export class DomainSettingsInfo {
  private _getDomainSettingsShareRef$!: Observable<DomainSettings>;

  constructor(
    private _extApi: ExtApi,
    private _sharedSettings: SharedSettings
  ) {}

  public getDomainSettings(): Observable<DomainSettings> {
    return (this._getDomainSettingsShareRef$ ??= from(
      getDomainSettings(this._extApi, this._sharedSettings.env.domainSettingsUrl!)
    ).pipe(shareReplay(1)));
  }
}

export function provideDomainSettingsInfo(): DomainSettingsInfo {
  return provideSingleton(DomainSettingsInfo, () => new DomainSettingsInfo(provideExtApi(), provideSharedSettings()));
}
