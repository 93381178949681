import { provideDiTokenValue } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { isDefined } from '@bcf-vanilla-ts-v1-shared/misc/pure-utils/is-defined';
import { APP_HOSTNAME } from '@bcflit-v1-configurators/tokens/app/token';

const memory: Record<string, string> = {};

function extractVersionNumber(): string {
  const host: string = provideDiTokenValue(APP_HOSTNAME);
  if (isDefined(memory[host])) {
    return memory[host];
  }
  let version: string = '';
  for (const char of host) {
    if (!isNaN(Number(char))) {
      version += char;
    }
  }
  memory[host] = version;
  return version;
}

export function resolveUrlWithVersionNumber(url: string): string {
  return url.replace('{versionNumber}', extractVersionNumber());
}
