import { CmsWidgetHeadline } from './cms-widget-headline';

export type CmsWidgetGuideItems = {
  readonly type: 'android' | 'ios';
  readonly typoHeading: string;
  readonly typoBodyHtml: string;
  readonly imageUrl: string;
};

export type CmsWidgetGuide = {
  readonly headline: CmsWidgetHeadline;
  readonly items: CmsWidgetGuideItems[];
  readonly _type: '_CmsWidgetGuide';
};

export function isCmsWidgetGuide(input: unknown): input is CmsWidgetGuide {
  return (input as any)?.['_type'] === '_CmsWidgetGuide';
}

function createCmsWidgetGuides(json: Record<string, any>): CmsWidgetGuideItems {
  return {
    type: json['type'],
    typoHeading: json['title'],
    typoBodyHtml: json['content'],
    imageUrl: json['image']
  };
}

export function createCmsWidgetGuidesData(json: Record<string, any>): CmsWidgetGuide {
  return {
    headline: {
      badgeTagTitle: json['label'],
      typoHeading: json['title'],
      typoBodyHtml: json['plainHtml']
    },
    items: json['items'].map(createCmsWidgetGuides),
    _type: '_CmsWidgetGuide'
  };
}
