import { provideDiTokenValue } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { LOCATION } from '@bcflit-v1-configurators/tokens/app/token';
import { isServer } from 'lit';
import { Observable, ReplaySubject } from 'rxjs';

type LocationKind = {
  origin: string;
  pathname: string;
  search: string;
};

function mapToUrlOriginWithPathname(location: LocationKind): string {
  return `${location.origin}${location.pathname}${location.search}`;
}

export class ActivatedUrl {
  private _currentPathname: string | undefined;
  private _currentPathname$: ReplaySubject<string> = new ReplaySubject<string>(1);

  private _currentFullUrl: string | undefined;
  private _currentFullUrl$: ReplaySubject<string> = new ReplaySubject<string>(1);

  private _currentOrigin: string | undefined;
  private _currentOrigin$: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor(private _location: Location) {
    if (!isServer) {
      window.addEventListener('click', () => this._checkAtNextTick());
      window.addEventListener('popstate', () => this._checkAtNextTick());
      window.addEventListener('hashchange', () => this._checkAtNextTick());
      window.addEventListener('routerUpdated', () => this._checkAtNextTick());
    } else {
      this._checkAtNextTick();
    }
  }

  public get url$(): Observable<string> {
    return this._currentPathname$.asObservable();
  }

  public get fullUrl$(): Observable<string> {
    return this._currentFullUrl$.asObservable();
  }

  public get originUrl$(): Observable<string> {
    return this._currentOrigin$.asObservable();
  }

  private _checkAtNextTick(): void {
    if (isServer) {
      this._checkUrl();
      return;
    }
    setTimeout(() => {
      this._checkUrl();
    }, 10);
  }

  private _checkUrl(): void {
    const pathname: string = this._location.pathname;
    if (this._currentPathname !== pathname) {
      this._currentPathname$.next(pathname);
      this._currentPathname = pathname;
    }
    const url: string = mapToUrlOriginWithPathname(this._location);
    if (this._currentFullUrl !== url) {
      this._currentFullUrl$.next(url);
      this._currentFullUrl = url;
    }
    const origin: string = this._location.origin;
    if (this._currentOrigin !== origin) {
      this._currentOrigin$.next(origin);
      this._currentFullUrl = origin;
    }
  }
}

export function provideActivatedUrl(): ActivatedUrl {
  return provideSingleton(ActivatedUrl, () => new ActivatedUrl(provideDiTokenValue(LOCATION)));
}
