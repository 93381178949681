import { CmsWidgetBanner, createCmsWidgetBannerData } from './cms-widget-banner';
import { CmsWidgetCards, createCmsWidgetCardsData } from './cms-widget-cards';
import { CmsWidgetGamesCollection, createCmsWidgetGamesCollectionData } from './cms-widget-games-collection';
import { CmsWidgetGuide, createCmsWidgetGuidesData } from './cms-widget-guide';
import { CmsWidgetIconsGroup, createCmsWidgetIconsGroupData } from './cms-widget-icons-group';
import { CmsWidgetMainBanner, createCmsWidgetMainBannerData } from './cms-widget-main-banner';

export type CmsStaticLandingPagesConfigurableKind = 'games' | 'banner' | 'payments' | 'providers' | 'grid' | 'guide';

export type CmsStaticLandingPagesContent = {
  readonly kind: CmsStaticLandingPagesConfigurableKind;
  readonly games: CmsWidgetGamesCollection | undefined;
  readonly payments: CmsWidgetIconsGroup | undefined;
  readonly providers: CmsWidgetIconsGroup | undefined;
  readonly banner: CmsWidgetBanner | undefined;
  readonly cards: CmsWidgetCards | undefined;
  readonly guide: CmsWidgetGuide | undefined;
};

export type CmsStaticLandingPages = {
  readonly slugName: string;
  readonly mainBanner: CmsWidgetMainBanner;
  readonly terms: string;
  readonly content: CmsStaticLandingPagesContent[];
};

export function createCmsStaticLandingPagesContent(contentJson: Record<string, any>): CmsStaticLandingPagesContent {
  return {
    kind: contentJson['kind'],
    games:
      (contentJson['kind'] as CmsStaticLandingPagesConfigurableKind) === 'games'
        ? createCmsWidgetGamesCollectionData(contentJson['games'])
        : undefined,
    banner:
      (contentJson['kind'] as CmsStaticLandingPagesConfigurableKind) === 'banner'
        ? createCmsWidgetBannerData(contentJson['banner'])
        : undefined,
    providers:
      (contentJson['kind'] as CmsStaticLandingPagesConfigurableKind) === 'providers'
        ? createCmsWidgetIconsGroupData(contentJson['providers'])
        : undefined,
    payments:
      (contentJson['kind'] as CmsStaticLandingPagesConfigurableKind) === 'payments'
        ? createCmsWidgetIconsGroupData(contentJson['payments'])
        : undefined,
    cards:
      (contentJson['kind'] as CmsStaticLandingPagesConfigurableKind) === 'grid'
        ? createCmsWidgetCardsData(contentJson['grid'])
        : undefined,
    guide:
      (contentJson['kind'] as CmsStaticLandingPagesConfigurableKind) === 'guide'
        ? createCmsWidgetGuidesData(contentJson['guide'])
        : undefined
  };
}

export function createCmsStaticLandingPagesData(json: Record<string, any>): CmsStaticLandingPages {
  return {
    slugName: json['slugName'],
    mainBanner: createCmsWidgetMainBannerData(json['hero']),
    terms: json['longTerms'],
    content: json['content'].map(createCmsStaticLandingPagesContent)
  };
}
