/* eslint-disable */
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */

import { provideDiTokenValue } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { BcfLitRoutes } from './bcf-lit-routes';
import { LOCATION } from '@bcflit-v1-configurators/tokens/app/token';
import { isServer } from 'lit';


/**
 * A root-level router that installs global event listeners to intercept
 * navigation.
 *
 * This class extends Routes so that it can also have a route configuration.
 *
 * There should only be one Router instance on a page, since the Router
 * installs global event listeners on `window` and `document`. Nested
 * routes should be configured with the `Routes` class.
 */
export class BcfLitRouter extends BcfLitRoutes {
  override hostConnected() {
    super.hostConnected();
    if (!isServer){
      window.addEventListener('click', this._onClick);
      window.addEventListener('popstate', this._onPopState);
    }
    // Kick off routed rendering by going to the current URL
    this.goto(this._location.pathname);
  }

  override hostDisconnected() {
    super.hostDisconnected();
    window.removeEventListener('click', this._onClick);
    window.removeEventListener('popstate', this._onPopState);
  }

  private _onClick = (e: MouseEvent) => {
    const isNonNavigationClick = e.button !== 0 || e.metaKey || e.ctrlKey || e.shiftKey;
    if (e.defaultPrevented || isNonNavigationClick) {
      return;
    }

    const anchor = e.composedPath().find((n) => (n as HTMLElement).tagName === 'A') as HTMLAnchorElement | undefined;
    if (
      anchor === undefined ||
      anchor.target !== '' ||
      anchor.hasAttribute('download') ||
      anchor.getAttribute('rel') === 'external'
    ) {
      return;
    }

    const href = anchor.href;
    if (href === '' || href.startsWith('mailto:')) {
      return;
    }

    const location = this._location;
    if (anchor.origin !== this._origin) {
      return;
    }

    e.preventDefault();
    if (href !== location.href) {
      window.history.pushState({}, '', href);
      this.goto(anchor.pathname);
    }
  };

  private _onPopState = (_e: PopStateEvent) => {
    this.goto(window.location.pathname);
  };

  private get _origin(){
    return this._location.origin || this._location.protocol + '//' + this._location.host;
  }

  private get _location(): Location {
    return provideDiTokenValue(LOCATION)
  }
}
