import { isDefined } from '@bcf-vanilla-ts-v1-shared/misc/pure-utils/is-defined';
import { pathJoin } from '@bcf-vanilla-ts-v1-shared/misc/pure-utils/path-join';
import { ModalKindStandard } from '@bcflit-v1-ui-modals-shared/types';
import { QueryParams } from '@tmf-shared-platform/activated-route/activated-route';
import { HistoryState, provideHistoryState } from '@tmf-shared-platform/history-state/history-state';
import { HistoryUrl } from '@tmf-shared-platform/history-url/history-url';
import { BcfLitRouter } from '@tmf-shared-platform/router/internal';
import { reverse } from 'rambdax-v11';

export function addBaseHrefToUrl(url: string | undefined): string | undefined {
  if (!url) {
    return url;
  }
  const baseHref: string = new URL(document.getElementsByTagName('base')[0].href).pathname;
  return pathJoin(baseHref, url);
}

export function routerPrepareUrl(newUrl: string, queryParams?: QueryParams, fragment?: string): string {
  const queryParamsString: string | undefined = queryParams
    ? stringifyQueryParams(new URLSearchParams(''), queryParams)
    : undefined;
  return (
    addBaseHrefToUrl(newUrl) + (queryParamsString ? `?${queryParamsString}` : '') + (fragment ? `#${fragment}` : '')
  );
}

/** @deprecated, please use new version from packages_v1 */
export function routerUpdateUrl(
  router: BcfLitRouter,
  newUrl: string,
  queryParams?: QueryParams,
  state?: Record<string, any>
): void {
  let newState: Record<string, any> = {
    path: addBaseHrefToUrl(newUrl)
  };
  if (state) {
    newState = {
      ...newState,
      ...state
    };
  }

  window.history.pushState(newState, '', routerPrepareUrl(newUrl, queryParams));
  const historyState: HistoryState = provideHistoryState();
  historyState.stateMightChanged();
  router.goto(newUrl);
}

/** @deprecated, please use new version from packages_v1 */
export function routerUpdateReplaceUrl(
  router: BcfLitRouter,
  newUrl: string,
  queryParams?: QueryParams,
  state?: Record<string, any>
): void {
  let newState: Record<string, any> = {
    path: addBaseHrefToUrl(newUrl)
  };
  if (state) {
    newState = {
      ...newState,
      ...state
    };
  }

  window.history.replaceState(newState, '', routerPrepareUrl(newUrl, queryParams));
  const historyState: HistoryState = provideHistoryState();
  historyState.stateMightChanged();
  router.goto(newUrl);
}

function routerUpdateUrlQueryParamsOrFragment(newUrl: string): void {
  window.history.pushState({ ...window.history.state, path: newUrl }, '', newUrl);
  const historyState: HistoryState = provideHistoryState();
  historyState.stateMightChanged();
  window.dispatchEvent(new Event('routerUpdated'));
}

export function routerGoBack(historyUrl: HistoryUrl): void {
  const findGoBackCount = (): number | undefined => {
    const currentUrl: string = historyUrl.history[historyUrl.history.length - 1];
    const reversed: string[] = reverse(historyUrl.history);
    for (let i: number = 1; i < reversed.length; i++) {
      if (getPath(reversed[i]) !== getPath(currentUrl)) {
        return i;
      }
    }
    return undefined;
  };

  const getPath = (url: string): string => {
    return url.split('#')[0].split('?')[0];
  };

  const goBack = (steps?: number): void => {
    if (steps) {
      window.history.go(steps);
    } else {
      window.history.back();
    }
    window.dispatchEvent(new Event('routerUpdated'));
  };

  const backIndex: number | undefined = findGoBackCount();
  if (isDefined(backIndex)) {
    const indexUnsigned: number = backIndex * -1;
    goBack(indexUnsigned);
    return;
  }
  goBack();
}

function stringifyQueryParams(currentQueryParams: URLSearchParams, newQueryParams: QueryParams): string {
  for (const [key, value] of Object.entries(newQueryParams)) {
    if (value === undefined) {
      currentQueryParams.delete(key);
    } else {
      currentQueryParams.set(key, value!.toString());
    }
  }
  return currentQueryParams.toString();
}

function mergeQueryParams(currentQueryParams: URLSearchParams, newQueryParams: QueryParams): QueryParams {
  const container: QueryParams = {};
  for (const [key, value] of Object.entries(currentQueryParams)) {
    container[key] = value;
  }
  for (const [key, value] of Object.entries(newQueryParams)) {
    if (value === undefined) {
      container[key] = undefined;
    } else {
      container[key] = value;
    }
  }
  return container;
}

function urlSearchParamsToQueryParams(searchParams: URLSearchParams): QueryParams {
  const container: QueryParams = {};
  for (const [key, value] of searchParams.entries()) {
    container[key] = value;
  }
  return container;
}

function getCurrentPathnameWithoutBaseHref(): string {
  const baseHref: string = new URL(document.getElementsByTagName('base')[0].href).pathname;
  const result: string = window.location.pathname.replace(baseHref, '');
  if (!result.startsWith('/')) {
    return `/${result}`;
  }
  return result;
}

/** @deprecated please use new version from packages_v1 */
export function routerUpdateQueryParams(currentQueryParams: URLSearchParams, newQueryParams: QueryParams): void {
  const newUrl: string = routerPrepareUrl(
    getCurrentPathnameWithoutBaseHref(),
    mergeQueryParams(currentQueryParams, newQueryParams)
  );
  routerUpdateUrlQueryParamsOrFragment(newUrl);
}

export function routerUpdateFragment(newFragment: string | undefined): void {
  const newUrl: string = routerPrepareUrl(
    getCurrentPathnameWithoutBaseHref(),
    urlSearchParamsToQueryParams(new URLSearchParams(window.location.search)),
    newFragment
  );
  routerUpdateUrlQueryParamsOrFragment(newUrl);
}

export function routerUpdateModalFragment(modalName: string | ModalKindStandard): void {
  routerUpdateFragment(`modal-${modalName}`);
}

export function routerUpdateModalFragmentWithParams(modalName: string, params: string[]): void {
  if (params.length > 0) {
    routerUpdateFragment(`modal-${modalName}$${params.join('~')}`);
    return;
  }
  routerUpdateFragment(`modal-${modalName}`);
}

export function routerUpdateBottomSheetFragment(modalName: string): void {
  routerUpdateFragment(`bottom-sheet-${modalName}`);
}
