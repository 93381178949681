import { DoRouterReplaceUrlData, DoRouterUpdateQueryParamsData, DoRouterUpdateUrlData, WeakListener } from './types';

export function pushToWeakListener(type: 'do-open-game-fullscreen-click'): void;
export function pushToWeakListener(type: 'do-open-game-info-click'): void;
export function pushToWeakListener(type: 'do-router-update-url', data?: DoRouterUpdateUrlData): void;
export function pushToWeakListener(type: 'do-router-replace-url', data?: DoRouterReplaceUrlData): void;
export function pushToWeakListener(type: 'do-router-update-query-params', data?: DoRouterUpdateQueryParamsData): void;
export function pushToWeakListener(type: 'router-back-from-open-game-to-game-list-category'): void;
export function pushToWeakListener<T>(type: WeakListener, data?: T): void {
  (globalThis as any)[type]?.(data);
}
