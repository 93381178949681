import { pushToWeakListener } from '@bcflit-v1-ui-shared/weak-listener/push-to-weak-listener';
import { QueryParams } from '@tmf-shared-platform/activated-route/activated-route';

export function routerUpdateUrl(newUrl: string, queryParams?: QueryParams, state?: Record<string, any>): void {
  pushToWeakListener('do-router-update-url', {
    url: newUrl,
    queryParams: queryParams,
    state: state
  });
}
